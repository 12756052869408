import Form from '@bfly/ui2/Form';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters, graphql } from 'relay-runtime';
import { boolean, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  emailMessages,
  makeOrganizationAdminMessages,
  organizationIdMessages,
  organizationInviteIdMessages,
  userDefinedRolesMessages,
} from '../messages/inviteActions';
import { InviteModalOrganizationQuery } from './__generated__/InviteModalOrganizationQuery.graphql';
import { InviteModalUserDefinedRolesQuery } from './__generated__/InviteModalUserDefinedRolesQuery.graphql';

export const getInviteSchema = () =>
  object({
    organizationInviteId: string(),
    organizationId: string().required(organizationIdMessages.required),
    email: string().email().required(emailMessages.required).trim(),
    makeOrganizationAdmin: boolean().default(false),
    roleId: string().required(userDefinedRolesMessages.required),
  });

interface UserRolesDropdownProps {
  domainId?: string | null;
  disabled: boolean | null;
}

const UUID_REG_EXP =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
function UserRolesDropdown({
  domainId = null,
  disabled,
}: UserRolesDropdownProps) {
  const { data: userDefinedRolesData } =
    useQuery<InviteModalUserDefinedRolesQuery>(
      graphql`
        query InviteModalUserDefinedRolesQuery($domainId: [String!]) {
          viewer {
            userDefinedRoles(domainId: $domainId) {
              edges {
                node {
                  roleId
                  name
                }
              }
            }
            systemDefinedRoles {
              edges {
                node {
                  roleId
                  name
                  roleType
                }
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'store-and-network',
        skip: !!disabled,
        variables: {
          domainId: [domainId ?? ''],
        },
      },
    );

  const publicOrgRole = 'PUBLIC_ORG';
  const rolesData = userDefinedRolesData && [
    ...getNodes(userDefinedRolesData!.viewer!.userDefinedRoles).map((item) => {
      return {
        name: item.name,
        value: item.roleId,
      };
    }),
    ...getNodes(userDefinedRolesData!.viewer!.systemDefinedRoles)
      .filter((item) =>
        domainId
          ? item.roleType !== publicOrgRole
          : item.roleType === publicOrgRole,
      )
      .map((item) => {
        return {
          name: item.name,
          value: item.roleId,
        };
      }),
  ];

  return (
    <Form.FieldGroup
      disabled={disabled || !rolesData}
      name="roleId"
      as={DropdownList}
      data={rolesData! ?? []}
      placeholder={userDefinedRolesMessages.placeholder}
      label={<FormattedMessage {...userDefinedRolesMessages.label} />}
      textField="name"
      dataKey="value"
      mapFromValue={({ value }) => value}
      data-cy="invite-userDefinedRole"
    />
  );
}

export default function InviteModal<TMutation extends MutationParameters>({
  defaultValue = {},
  ...props
}: ResourceModalProps<TMutation>) {
  const { organizationId } = defaultValue;
  const isCreate = !organizationId;
  const { formatMessage } = useIntl();

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>('');

  const { data: organizationData } = useQuery<InviteModalOrganizationQuery>(
    graphql`
      query InviteModalOrganizationQuery($organizationId: String!) {
        organization(organizationId: $organizationId) {
          domain {
            domainId
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      skip: !selectedOrganizationId.match(UUID_REG_EXP),
      variables: {
        organizationId: selectedOrganizationId,
      },
    },
  );
  const schemaDefaults = getInviteSchema().getDefault();
  return (
    <ResourceModal<TMutation>
      {...props}
      onClose={() => {
        setSelectedOrganizationId('');
        props.onClose();
      }}
      defaultValue={{ ...schemaDefaults, ...defaultValue }}
      schema={getInviteSchema()}
    >
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="organizationInviteId"
          label={
            <FormattedMessage
              {...organizationInviteIdMessages.labelRequired}
            />
          }
          placeholder={formatMessage(organizationInviteIdMessages.placeholder)}
          data-cy="inviteModal-organizationInviteId"
        />
      )}
      <Form.FieldGroup
        name="organizationId"
        disabled={!isCreate}
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        data-cy="inviteModal-organizationId"
        onChange={(event) => setSelectedOrganizationId(event.target.value)}
      />
      <Form.FieldGroup
        name="email"
        disabled={!isCreate || !selectedOrganizationId}
        label={<FormattedMessage {...emailMessages.label} />}
        placeholder={formatMessage(emailMessages.placeholder)}
        data-cy="inviteModal-emailField"
      />
      <UserRolesDropdown
        disabled={!organizationData || !selectedOrganizationId}
        domainId={
          organizationData && organizationData?.organization?.domain?.domainId
        }
      />
      <Form.FieldGroup
        disabled="true"
        name="makeOrganizationAdmin"
        label={<FormattedMessage {...makeOrganizationAdminMessages.label} />}
        data-cy="inviteModal-makeOrganizationAdmin"
      />
    </ResourceModal>
  );
}
