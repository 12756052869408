export default [
  {
    label: 'Simpsons EF',
    value: '16e32b30-224c-4bdd-8e8c-a58e4872b2b8',
  },
  {
    label: 'Auto Bladder Volume',
    value: '31b79370-d322-48b6-9ab0-bae10ee36ea8',
  },
  {
    label: 'Auto B-line Count',
    value: '7e42df4d-5cf6-45ca-b72a-c3ac33c87c1b',
  },
  {
    label: 'Manual Bladder Volume',
    value: '1c709203-ac3b-42bc-a974-e0e52e820cfe',
  },
  {
    label: 'Manual Gastric Volume',
    value: 'be108f5a-d0fb-4e66-a0df-1592df2d0738',
  },
  {
    label: 'PLAX Guidance',
    value: 'abc55437-ba8b-4b14-95bd-954195a4e36c',
  },
  {
    label: 'A4C Guidance',
    value: '8d8cb192-31c5-4237-8aed-2947ce7a2557',
  },
  {
    label: 'PSAX Guidance',
    value: '10296a5f-d1ab-4dec-83f8-553ea9c01806',
  },
  {
    label: 'B-Lines Guidance',
    value: '477e9500-5834-4014-8e03-4d83352550a4',
  },
  {
    label: 'SUBX Guidance',
    value: 'a666ff60-cdab-43b5-b7fc-17d40d25e39d',
  },
  {
    label: 'RUQ Guidance',
    value: '114d3d4a-cdd3-4a21-844c-0ebd5eeb3970',
  },
  {
    label: 'Midline',
    value: 'cbed81de-ddb3-4a49-a14b-9f2cbe4e8b4b',
  },
  {
    label: 'OB Calcs',
    value: '509f708f-b318-4026-91c3-62450a866a37',
  },
  {
    label: 'Fetal Biometrics',
    value: '5ee85c17-ef98-4501-8b05-79f791f0aef0',
  },
  {
    label: 'Amniotic Fluid Index',
    value: 'e3ca07c1-6914-426e-ae34-67605dc96dc4',
  },
  {
    label: 'Crown Rump Length',
    value: '81298db1-feb1-4684-a3bd-bf787a71a9c7',
  },
  {
    label: 'Gestational Age',
    value: 'ccb6b11c-fa54-4497-8a04-0359bb8bbe3f',
  },
  {
    label: 'Mean Sac Diameter',
    value: 'c97e2dea-76be-11eb-9439-0242ac130002',
  },
  {
    label: 'Bi-plane',
    value: 'a13086f9-6e67-4842-a86f-f4804f093363',
  },
  {
    label: 'Left Needle',
    value: 'a18f7935-82e9-43bc-bcff-d10bba584072',
  },
  {
    label: 'Right Needle',
    value: '1a1ef32f-58ee-488d-aab4-9d0d6ad9e3bf',
  },
  {
    label: 'Lung Protocol',
    value: 'b54d16c7-94a2-49fb-a046-fc7a595d3184',
  },
  {
    label: 'eFast Protocol',
    value: '93f1653a-73f8-4744-94b2-8f71049202a0',
  },
  {
    label: 'eFast Walkthrough',
    value: '54b038d2-359d-4110-9b38-c1cd5ac8a768',
  },
  {
    label: 'DVT Protocol',
    value: 'b2899b8d-53de-4c53-acd2-1747a791dcff',
  },
  {
    label: 'Aorta Protocol',
    value: '1e38d737-f9b5-4b48-af09-0fe04b59d5a4',
  },
  {
    label: 'Cardiac Protocol',
    value: 'd7bba005-f8e0-4204-a8f2-dec511467162',
  },
  {
    label: 'AFAST Protocol',
    value: '3fa36bad-4b9e-4baf-85a6-429c0b9f4ac6',
  },
  {
    label: 'TFAST Protocol',
    value: 'c87aa2f1-d2c7-443e-b61b-fb66eab067dd',
  },
  {
    label: 'Colic FLASH Protocol',
    value: '827a0111-d04f-41cd-a39b-81044539916c',
  },
  {
    label: 'Lung Anatomical Labeling',
    value: '4558aef4-9af4-4b7d-9353-438ba332596d',
  },
  {
    label: 'A4C Anatomical Labeling',
    value: '68c90bcc-7617-4e9e-8a29-0745df1be1cf',
  },
  {
    label: 'PLAX Anatomical Labeling',
    value: 'eb100aeb-e22d-4ff4-b710-97fc400fa541',
  },
  {
    label: 'PSAX Anatomical Labeling',
    value: 'b8b0ed59-94a6-4c87-80d9-55ff88ca6508',
  },
  {
    label: 'SUBX Anatomical Labeling',
    value: 'ca9edb3e-6652-4791-a1ab-1d1e7de5aec0',
  },
  {
    label: 'RUQ Anatomical Labeling',
    value: '0f36b041-db05-4922-99c5-39bbbf336dbc',
  },
  {
    label: 'Generic Manual Volume',
    value: '4d064089-eaaa-4b8f-a638-0b639639fa12',
  },
  {
    label: 'Diameter Reduction',
    value: 'e6f1859f-2f91-44cd-8710-52de76077de1',
  },
  {
    label: 'Alpha/Beta Measurement',
    value: 'd3564462-69db-40b7-9da3-d7819878c13f',
  },
  {
    label: 'Hepato Renal Index',
    value: '34434fb6-6981-4187-91dd-a1edd8d94efe',
  },
  {
    label: 'Cardiac Output',
    value: 'd285609b-54e4-42a6-ae5e-f4965e7d98d3',
  },
];
