/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
export type UpdateMembershipModal_membership = {
    readonly id: string;
    readonly type: OrganizationMembershipType | null;
    readonly organizationMembershipId: string | null;
    readonly organization: {
        readonly organizationId: string | null;
    } | null;
    readonly user: {
        readonly userId: string | null;
        readonly name: string | null;
    } | null;
    readonly isNurse: boolean | null;
    readonly canFinalize: boolean | null;
    readonly canQa: boolean | null;
    readonly " $refType": "UpdateMembershipModal_membership";
};
export type UpdateMembershipModal_membership$data = UpdateMembershipModal_membership;
export type UpdateMembershipModal_membership$key = {
    readonly " $data"?: UpdateMembershipModal_membership$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateMembershipModal_membership">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateMembershipModal_membership",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationMembershipId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
(node as any).hash = '113012b33c64ba156a449c2e795b7bda';
export default node;
