import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import MutationButton from '@bfly/ui2/MutationButton';
import omit from 'lodash/omit';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { hasButterflyAccessRoles } from 'utils/accessRolesUtils';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteDomainUserAction from './DeleteDomainUserAction';
import DeleteFlatUserAction from './DeleteFlatUserAction';
import DeleteUserAction from './DeleteUserAction';
import SetFlatUserRoleAction from './SetFlatUserRoleAction';
import UndeleteDomainUserAction from './UndeleteDomainUserAction';
import UndeleteFlatUserAction from './UndeleteFlatUserAction';
import { UpdateUserModal } from './UpdateUserModal';
import { domainUserFields } from './UserModal';
import { FlatUserDetailPage_RequestUserReviewMutation as RequestUserReviewMutation } from './__generated__/FlatUserDetailPage_RequestUserReviewMutation.graphql';
import { FlatUserDetailPage_ResetUserReviewRequestsMutation as ResetUserReviewRequestsMutation } from './__generated__/FlatUserDetailPage_ResetUserReviewRequestsMutation.graphql';
import { FlatUserDetailPage_flatUser$key } from './__generated__/FlatUserDetailPage_flatUser.graphql';
import { SetFlatUserRoleAction_roles$key } from './__generated__/SetFlatUserRoleAction_roles.graphql';

const fragment = graphql`
  fragment FlatUserDetailPage_flatUser on FlatUser {
    ...UpdateUserModal_user
    ...DeleteFlatUserAction_user
    ...DeleteUserAction_user
    ...DeleteDomainUserAction_user
    ...UndeleteDomainUserAction_user
    ...SetFlatUserRoleAction_user
    ...UndeleteFlatUserAction_user
    id
    flatUserId
    auth0Id
    userId
    name
    email
    emails {
      email
      verified
      preferred
      domain {
        domainId
      }
    }
    hasUnacceptedInvite: hasPendingOrganizationInvite
    specialtyKey
    specialtyFreeform
    phoneNumber
    placeOfWork {
      placeOfWorkId
      name
    }
    placeOfWorkFreeform
    setupAt
    dateLastActive
    userCreatedAt
    userDeletedAt
    lastReviewRequestedAt
    lastCloudReviewRequestTriggeredAt
    lastImmediateReviewRequestTriggeredAt
    isImplicitPublic
    # domain user fields
    domainId
    domainAdmin: type
    domainUserCreatedAt
    domainUserDeletedAt
    integrationDisplayNameFirst
    integrationDisplayNameMiddle
    integrationDisplayNameLast
    integrationConfigs {
      domainUserIntegrationConfigId
    }
    hasTableauDashboardAccess
    isNurse
    canFinalize
    canQa
    role {
      id
      roleId
      name
      roleType
    }
    domain {
      subdomainLabel
    }
  }
`;

const userDefinedRolesFragment = graphql`
  fragment FlatUserDetailPage_roles on Viewer {
    userDefinedRoles(domainId: $domainId) @skip(if: $skipRolesQuery) {
      edges {
        node {
          domainId
          name
          userDefinedRoleId
        }
      }
    }
  }
`;

interface FlatUserDetailPageProps {
  flatUser: FlatUserDetailPage_flatUser$key;
  viewer: SetFlatUserRoleAction_roles$key;
}

const requestUserReview = graphql`
  mutation FlatUserDetailPage_RequestUserReviewMutation(
    $input: RequestUserReviewInput!
  ) {
    requestUserReview(input: $input) {
      __typename
      user {
        lastImmediateReviewRequestTriggeredAt
      }
    }
  }
`;
const resetReviewRequests = graphql`
  mutation FlatUserDetailPage_ResetUserReviewRequestsMutation(
    $input: ResetUserReviewRequestsInput!
  ) {
    resetUserReviewRequests(input: $input) {
      __typename
      user {
        lastReviewRequestedAt
        lastImmediateReviewRequestTriggeredAt
        lastCloudReviewRequestTriggeredAt
      }
    }
  }
`;

export default function FlatUserDetailPage({
  flatUser,
  viewer: viewerData,
}: FlatUserDetailPageProps) {
  const fragmentData = useFragment(fragment, flatUser);
  const { userDefinedRoles } = useFragment(
    userDefinedRolesFragment,
    viewerData,
  );
  const rolesByIdLookup = useMemo(() => {
    if (!userDefinedRoles || !userDefinedRoles.edges) {
      return {};
    }
    return userDefinedRoles.edges.reduce((acc, edge) => {
      if (edge?.node && edge.node.userDefinedRoleId) {
        acc[edge.node.userDefinedRoleId] = edge.node.name;
      }

      return acc;
    }, {});
  }, [userDefinedRoles]);
  const {
    userId,
    domainId,
    email,
    hasUnacceptedInvite,
    placeOfWork,
    name,
    isImplicitPublic,
    userDeletedAt,
    domainUserDeletedAt,
    role,
  } = fragmentData;

  const { placeOfWorkId } = placeOfWork || {};
  useBreadcrumbDetector(pageTitles.user, name);
  const { canWrite, viewer } = usePermissionsContext();

  const viewerCanWrite = domainId
    ? canWrite(Resource.DOMAIN_USERS) && canWrite(Resource.USERS)
    : canWrite(Resource.USERS);

  const deleted = domainId
    ? !!userDeletedAt && !!domainUserDeletedAt
    : !!userDeletedAt;

  let data = fragmentData as Record<string, unknown>;
  if (domainId) {
    data = { ...data };
    data.domainAdmin = data.domainAdmin === 'ADMIN';
    delete data.type;
  }

  if (!hasButterflyAccessRoles(viewer)) {
    data = { ...data };
    delete data.role;
  }
  const renderMap = useMemo(() => {
    if (!role) {
      return undefined;
    }
    let { name: roleName } = role;
    let roleDetailsUrl =
      role.roleType === 'SYSTEM_DEFINED'
        ? `/access-roles/system-defined/${role.roleId}`
        : `/access-roles/user-defined/${role.roleId}`;

    // a workaround till backend is updated & graphpql role resolution are fixed see CBUG-258
    if (!roleName) {
      roleName =
        role.roleType === 'SYSTEM_DEFINED' && role.roleId
          ? rolesByIdLookup[role.roleId]
          : '';

      roleDetailsUrl = `/access-roles/user-defined/${role.roleId}`;
    }

    return {
      role: {
        label: () => 'role',
        value: () => (
          <Link as={Anchor} to={roleDetailsUrl} data-cy="role-details-link">
            {roleName}
          </Link>
        ),
      },
    };
  }, [role, rolesByIdLookup]);

  return (
    <PermissionsGuard resource={Resource.USERS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.user} />}
        name={fragmentData.name!}
        data={omit(
          data,
          ['flatUserId'].concat(
            domainId
              ? []
              : [
                  ...domainUserFields,
                  'integrationConfigs',
                  'domainUserCreatedAt',
                  'domainUserDeletedAt',
                ],
            hasButterflyAccessRoles(viewer) ? [] : ['role'],
          ),
        )}
        renderMap={renderMap}
        quickCopyFields={[
          'auth0Id',
          'userId',
          'domainId',
          'name',
          'email',
          'phoneNumber',
        ]}
        objectFields={['role']}
        resourceActions={
          canWrite(Resource.USER_REVIEW_REQUESTS) && (
            <>
              <MutationButton<RequestUserReviewMutation>
                mutation={requestUserReview}
                input={{ userId: userId! }}
                updater={(store, response) => {
                  store
                    .get(fragmentData.id!)!
                    .setValue(
                      response.requestUserReview?.user
                        ?.lastImmediateReviewRequestTriggeredAt,
                      'lastImmediateReviewRequestTriggeredAt',
                    );
                }}
                variant="secondary"
                successToastMessage={
                  <FormattedMessage
                    id="UserAction.requestReview.successful"
                    defaultMessage="Request review is successful"
                  />
                }
                errorToastMessage={
                  <FormattedMessage
                    id="UserAction.requestReview.error"
                    defaultMessage="Failed to execute request review"
                  />
                }
              >
                <FormattedMessage
                  id="UserAction.requestReview"
                  defaultMessage="Request Review"
                />
              </MutationButton>
              <MutationButton<ResetUserReviewRequestsMutation>
                mutation={resetReviewRequests}
                input={{ userId: userId! }}
                updater={(store, response) => {
                  const flatUserNode = store.get(fragmentData.id!)!;
                  for (const [key, value] of Object.entries(
                    response.resetUserReviewRequests!.user!,
                  )) {
                    flatUserNode.setValue(value, key);
                  }
                }}
                variant="secondary"
                successToastMessage={
                  <FormattedMessage
                    id="UserAction.resetReviewRequests.successful"
                    defaultMessage="Reset review requests is successful"
                  />
                }
                errorToastMessage={
                  <FormattedMessage
                    id="UserAction.resetReviewRequests.error"
                    defaultMessage="Failed to execute reset review requests"
                  />
                }
              >
                <FormattedMessage
                  id="UserAction.resetReviewRequests"
                  defaultMessage="Reset Review Requests"
                />
              </MutationButton>
            </>
          )
        }
        relatedItems={
          <>
            <RelatedItem
              name="Memberships"
              to={{
                pathname: '/memberships',
                query: { userId },
              }}
            >
              <FormattedMessage
                id="userDetail.relatedItem.memberships"
                defaultMessage="Memberships"
              />
            </RelatedItem>
            {domainId && (
              <>
                <RelatedItem name="Domain" to={`/domains/${domainId}`}>
                  <FormattedMessage
                    id="userDetail.relatedItem.domain"
                    defaultMessage="Domain"
                  />
                </RelatedItem>
                <RelatedItem
                  name="Integration Configs"
                  to={{
                    pathname: '/domain-user-integrations-configs',
                    query: { userId },
                  }}
                >
                  <FormattedMessage
                    id="userDetail.relatedItem.domainUserIntegrationsConfigs"
                    defaultMessage="Integration Configs"
                  />
                </RelatedItem>
              </>
            )}
            {hasUnacceptedInvite && email && (
              <RelatedItem name="Invites" to={`/invites?email=${email}`}>
                <FormattedMessage
                  id="userDetail.relatedItem.invites"
                  defaultMessage="Invites"
                />
              </RelatedItem>
            )}
            {placeOfWorkId && (
              <RelatedItem
                name="Place of Work"
                to={`/place-of-work/${placeOfWorkId}`}
              >
                <FormattedMessage
                  id="userDetail.relatedItem.placeOfWork"
                  defaultMessage="Place of Work"
                />
              </RelatedItem>
            )}
            {hasButterflyAccessRoles(viewer) && !!role && (
              <RelatedItem
                name="Butterfly Access Role"
                to={
                  role.roleType === 'SYSTEM_DEFINED'
                    ? `/access-roles/system-defined/${role.roleId}`
                    : `/access-roles/user-defined/${role.roleId}`
                }
              >
                <FormattedMessage
                  id="userDetail.relatedItem.butterflyAccessRole"
                  defaultMessage="Butterfly Access Role"
                />
              </RelatedItem>
            )}
          </>
        }
        crudActions={
          <>
            {!!domainId && (
              <SetFlatUserRoleAction
                userRef={fragmentData}
                viewerRef={viewerData}
              />
            )}
            {viewerCanWrite && (
              <>
                <UpdateAction
                  renderModal={(props) => (
                    <UpdateUserModal {...props} userRef={fragmentData} />
                  )}
                />
                {isImplicitPublic ? (
                  <>
                    {domainUserDeletedAt ? (
                      <UndeleteDomainUserAction userRef={fragmentData} />
                    ) : (
                      <DeleteDomainUserAction userRef={fragmentData} />
                    )}
                    <DeleteUserAction userRef={fragmentData} />
                  </>
                ) : (
                  <>
                    {deleted ? (
                      <UndeleteFlatUserAction userRef={fragmentData} />
                    ) : (
                      <DeleteFlatUserAction userRef={fragmentData} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
      />
    </PermissionsGuard>
  );
}
