/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type RoleType = "PUBLIC_ORG" | "SYSTEM_DEFINED" | "USER_DEFINED" | "%future added value";
export type FlatUserDetailPage_flatUser = {
    readonly id: string;
    readonly flatUserId: string | null;
    readonly auth0Id: string | null;
    readonly userId: string | null;
    readonly name: string | null;
    readonly email: string | null;
    readonly emails: ReadonlyArray<{
        readonly email: string | null;
        readonly verified: boolean | null;
        readonly preferred: boolean | null;
        readonly domain: {
            readonly domainId: string | null;
        } | null;
    } | null> | null;
    readonly hasUnacceptedInvite: boolean | null;
    readonly specialtyKey: string | null;
    readonly specialtyFreeform: string | null;
    readonly phoneNumber: string | null;
    readonly placeOfWork: {
        readonly placeOfWorkId: string | null;
        readonly name: string | null;
    } | null;
    readonly placeOfWorkFreeform: string | null;
    readonly setupAt: string | null;
    readonly dateLastActive: string | null;
    readonly userCreatedAt: string | null;
    readonly userDeletedAt: string | null;
    readonly lastReviewRequestedAt: string | null;
    readonly lastCloudReviewRequestTriggeredAt: string | null;
    readonly lastImmediateReviewRequestTriggeredAt: string | null;
    readonly isImplicitPublic: boolean | null;
    readonly domainId: string | null;
    readonly domainAdmin: DomainUserType | null;
    readonly domainUserCreatedAt: string | null;
    readonly domainUserDeletedAt: string | null;
    readonly integrationDisplayNameFirst: string | null;
    readonly integrationDisplayNameMiddle: string | null;
    readonly integrationDisplayNameLast: string | null;
    readonly integrationConfigs: ReadonlyArray<{
        readonly domainUserIntegrationConfigId: string | null;
    } | null> | null;
    readonly hasTableauDashboardAccess: boolean | null;
    readonly isNurse: boolean | null;
    readonly canFinalize: boolean | null;
    readonly canQa: boolean | null;
    readonly role: {
        readonly id: string;
        readonly roleId: string | null;
        readonly name: string | null;
        readonly roleType: RoleType | null;
    } | null;
    readonly domain: {
        readonly subdomainLabel: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateUserModal_user" | "DeleteFlatUserAction_user" | "DeleteUserAction_user" | "DeleteDomainUserAction_user" | "UndeleteDomainUserAction_user" | "SetFlatUserRoleAction_user" | "UndeleteFlatUserAction_user">;
    readonly " $refType": "FlatUserDetailPage_flatUser";
};
export type FlatUserDetailPage_flatUser$data = FlatUserDetailPage_flatUser;
export type FlatUserDetailPage_flatUser$key = {
    readonly " $data"?: FlatUserDetailPage_flatUser$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlatUserDetailPage_flatUser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlatUserDetailPage_flatUser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auth0Id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEmail",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferred",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Domain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "hasUnacceptedInvite",
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingOrganizationInvite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceOfWork",
      "kind": "LinkedField",
      "name": "placeOfWork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeOfWorkId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateLastActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastReviewRequestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCloudReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastImmediateReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImplicitPublic",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": "domainAdmin",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserIntegrationConfig",
      "kind": "LinkedField",
      "name": "integrationConfigs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainUserIntegrationConfigId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTableauDashboardAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleId",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subdomainLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateUserModal_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteFlatUserAction_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteUserAction_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteDomainUserAction_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteDomainUserAction_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SetFlatUserRoleAction_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteFlatUserAction_user"
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
})();
(node as any).hash = '8fb4863eb7abe357bf2415a5dce22053';
export default node;
